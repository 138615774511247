// 此文件为生成文件，不要直接编辑
import _page from 'E:\\uni星球\\unifamily-new\\unifamily\\manager\\src\\models\\page.js';
import _settings from 'E:\\uni星球\\unifamily-new\\unifamily\\manager\\src\\models\\settings.js';
import _side from 'E:\\uni星球\\unifamily-new\\unifamily\\manager\\src\\models\\side.js';
import _menu from 'E:\\uni星球\\unifamily-new\\unifamily\\manager\\src\\models\\menu.js';
import _system from 'E:\\uni星球\\unifamily-new\\unifamily\\manager\\src\\models\\system.js';

export const page = _page;
export const settings = _settings;
export const side = _side;
export const menu = _menu;
export const system = _system;

